<script>
import Footer from "@/components/navigation/Footer";

export default {
  name: "confirm-error",

  components: {
    Footer,
  },

  data: () => ({
    logoImage: require("@/assets/images/logos/logo-icon.svg"),
    logoText: require("@/assets/images/logos/logo-text.svg"),
  }),
};
</script>

<template>
  <div id="home" class="fill-height">
    <div class="pt-10">
      <v-container class="my-15">
        <v-card>
          <v-card-text>
            <div class="text-center">
              <v-fade-transition>
                <v-icon disabled size="128" v-if="loading">
                  mdi-emoticon-neutral
                </v-icon>
              </v-fade-transition>
              <v-fade-transition>
                <v-icon color="error" size="128"> mdi-emoticon-sad </v-icon>
              </v-fade-transition>
            </div>
            <div class="mx-15 mt-5">
              <p class="text-body-1 font-weight-bold mb-10">
                {{ $t("response.fail") }}
              </p>
              <p class="text-body-1 mb-7">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Aliquam, architecto, cum exercitationem ipsam itaque nemo
                numquam obcaecati omnis perferendis placeat porro quae quidem
                quod similique suscipit tempora voluptatum? Delectus, dolorum?
              </p>
              <p class="text-body-1 mb-10">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              </p>
              <p class="text-body-1 font-weight-bold">INNIRM Team</p>
            </div>
          </v-card-text>
        </v-card>
      </v-container>
    </div>
    <Footer />
  </div>
</template>

<style scoped lang="scss">
.full-height {
  height: 100% !important;
}
.full-width {
  width: 100% !important;
}
@media (min-width: 1904px) {
  .container {
    max-width: 1185px !important;
  }
}
</style>
